import SVGclose from '@fractal/primitives/SVGs/icons/close'
import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { IModal } from './types'
import styles from './Modal.module.scss'

function Modal({
  children,
  modalOpen,
  onRequestClose,
  closeModal,
  contentLabel,
  modalWidth,
  modalWidthSm,
  modalHeight,
  dataTestid,
  position,
  overlayStyle,
  ...props
}: IModal) {
  useEffect(() => {
    const nextElement = document.getElementById('__next')
    const articleElement = document.getElementById('article-body')
    const appElement = nextElement
      ? '#__next'
      : articleElement
        ? '#article-body'
        : undefined

    appElement && ReactModal.setAppElement(appElement)
  }, [])

  function onClose() {
    if (closeModal) {
      closeModal()
    }
  }

  useEffect(() => {
    if (!modalOpen || (modalOpen && document.body.style.overflow)) return

    const scrollBarWidth = window.innerWidth - document.body.offsetWidth
    document.body.style.overflow = 'hidden'
    document.body.style.borderRight = `${scrollBarWidth}px solid transparent`

    return () => {
      document.body.style.overflow = ''
      document.body.style.borderRight = ''
    }
  }, [modalOpen])
  
  const customStyles = {
    content: {
      '--modal-width': `${modalWidth}px`,
      '--modal-width-sm': `${modalWidthSm}px`,
      '--modal-height': `${modalHeight}px`
    },
    overlay: overlayStyle
  }

  return (
    <div
      {...props}
      data-testid={dataTestid || 'modal'}
    >
      <ReactModal
        isOpen={!!modalOpen}
        onRequestClose={onRequestClose}
        contentLabel={contentLabel}
        dataTestid={dataTestid}
        closeTimeoutMS={position === 'right' ? 300 : 0}
        position={position}
        className={`${styles.modal} ${position === 'right' ? styles.right : styles.default}`}
        style={customStyles}
      >
        <p role='status' className='sr-only' tabIndex={-1}>
          {contentLabel} modal open
        </p>
        <button
          data-testid='modalCloseButton'
          onClick={onClose}
          tabIndex={0}
          autoFocus
          aria-label='close'
          // @ts-ignore
          data-testid='modal-close-button'
          className={`Btn-link p-sm-absolute top-sm-0 right-sm-0 target d-sm-inline-flex flex-sm-align-center flex-sm-justify-center
            z-index-100 p-sm-0 text-foreground-darkblue no-underline text-nowrap border-sm-0 ${styles.closeButton}`}
        >
          <SVGclose className='vicon m-sm-4' />
        </button>
        {children}
      </ReactModal>
    </div>
  )
}

Modal.defaultProps = {
  position: 'default',
  modalOpen: false
}

export default Modal
