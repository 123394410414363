import SVGdropdown from '@fractal/primitives/SVGs/icons/dropdown'
import { Variants } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { motion } from 'framer-motion'
import { v4 as uuidV4 } from 'uuid'
import { IInlineSelectorProps } from './InlineSelector.interface'
import styles from './InlineSelector.module.scss'

// Accessibility for this component is based on the following documentation:
// https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html

interface IElementsRef {
  button: HTMLSpanElement | null
  modal: HTMLUListElement | null
  items: HTMLLIElement[] | null[]
  anchor: HTMLSpanElement | null
}

export const dropdownVariants: Variants = {
  closed: {
    opacity: 0,
    pointerEvents: 'none',
    scale: 0,
    transformOrigin: '50% 0'
  },
  opened: {
    opacity: 1,
    pointerEvents: 'all',
    scale: 1,
    transformOrigin: '50% 0'
  }
}

const InlineSelector = ({
  options,
  onChange,
  id,
  label,
  labelledby,
  selectedValue,
  placeholder,
  optionStyledAs,
  backgroundColor,
  setTextDangerously,
  ...props
}: IInlineSelectorProps) => {
  const [opened, setOpened] = useState(false)
  const [uniqueId] = useState(id || uuidV4())
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)
  const containerRef = useRef<Element | null>(null)
  const elementsRef = useRef<IElementsRef>({
    button: null,
    modal: null,
    anchor: null,
    items: []
  })

  useEffect(() => {
    if (selectedValue) {
      const selectedValueIndex = options.indexOf(selectedValue)
      if (selectedValueIndex !== selectedItemIndex) {
        setSelectedItemIndex(selectedValueIndex)
      }
    }
  }, [selectedValue])

  const handleUserSelect = index => {
    setSelectedItemIndex(index)
    onChange && onChange(options[index])
  }

  const handleOpened = (isOpened = false) => {
    if (isOpened) {
      setTimeout(() => elementsRef.current.modal?.focus(), 300)
    } else {
      setTimeout(() => elementsRef.current.button?.focus(), 300)
    }

    setOpened(isOpened)
  }

  const handleKeyboardNavigation = event => {
    let index = 0
    switch (event.keyCode) {
      case 38:
        // 38 arrow up
        event.preventDefault()
        handleOpened(true)
        index =
          selectedItemIndex - 1 < 0 ? selectedItemIndex : selectedItemIndex - 1
        handleUserSelect(index)
        break
      case 40:
        // 40 arrow down
        event.preventDefault()
        handleOpened(true)
        index =
          selectedItemIndex + 1 > options.length - 1
            ? selectedItemIndex
            : selectedItemIndex + 1
        handleUserSelect(index)
        break
      case 36:
        // 36 home
        if (opened) {
          event.preventDefault()
          handleOpened(true)
          handleUserSelect(0)
        }
        break
      case 35:
        // 35 end
        if (opened) {
          event.preventDefault()
          handleOpened(true)
          handleUserSelect(options.length - 1)
        }
        break
      case 13:
        // 13 enter
        event.preventDefault()
        handleOpened(!opened)
        break
      case 27:
        // 27 esc
        event.preventDefault()
        handleOpened(false)
        break
    }
  }

  const handleItemClick = index => {
    handleUserSelect(index)
    handleOpened(false)
  }

  useEffect(() => {
    containerRef.current = document.createElement('div')
    document.body.appendChild(containerRef.current)
  }, [])

  const getStyles = () => {
    if (!elementsRef.current.anchor) return

    const rect = elementsRef.current.anchor.getBoundingClientRect()
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    let top = rect.top + scrollTop - 8
    let left = rect.left + scrollLeft - 8

    if (
      elementsRef.current.modal &&
      left + elementsRef.current.modal.offsetWidth > window.innerWidth
    ) {
      left = window.innerWidth - elementsRef.current.modal.offsetWidth - 30
    }

    return {
      top,
      left
    }
  }

  const OptionsElement = optionStyledAs || 'li'

  return (
    <div className={`p-sm-relative d-sm-inline text-foreground-darkblue ${styles.inlineSelector}`}>
      <span
        {...props}
        role='button'
        tabIndex={0}
        onClick={() => handleOpened(!opened)}
        onKeyDown={handleKeyboardNavigation}
        id={`${uniqueId}_button`}
        aria-labelledby={`${labelledby} ${uniqueId}_button`}
        aria-haspopup='listbox'
        aria-expanded={opened}
        ref={buttonRef => (elementsRef.current.button = buttonRef)}
        className={`${styles.button} ${props.styledAs === 'madlib' ? `bg-background-high-${backgroundColor || 'ochreTint'} ${styles.madlib}` : ''} `}
      >
        {'\u00a0'}
        <span ref={anchorRef => (elementsRef.current.anchor = anchorRef)} />
        <span
          style={{ whiteSpace: 'normal' }}
          dangerouslySetInnerHTML={
            setTextDangerously
              ? {
                __html: options[selectedItemIndex] || placeholder || ''
              }
              : undefined
          }
        >
          {setTextDangerously
            ? undefined
            : options[selectedItemIndex] || placeholder}
        </span>
        {'\u00a0'}
        {props.styledAs === 'madlib' && <SVGdropdown className='vicon' />}
      </span>
      {containerRef.current &&
        ReactDOM.createPortal(
          <>
            {opened && (
              <div
                className='p-sm-fixed top-sm-0 left-sm-0 right-sm-0 bottom-sm-0 z-index-2'
                onClick={() => handleOpened(false)}
              />
            )}
            <motion.ul
              variants={dropdownVariants}
              animate={opened ? 'opened' : 'closed'}
              initial='closed'
              ref={modalRef => (elementsRef.current.modal = modalRef)}
              role='listbox'
              tabIndex={-1}
              aria-label={label}
              aria-labelledby={labelledby}
              aria-activedescendant={`${uniqueId}_option_${selectedItemIndex}`}
              onKeyDown={handleKeyboardNavigation}
              onBlur={() => handleOpened(false)}
              className={`p-sm-absolute top-sm-0 left-sm-0 bg-background-high-white f-sm-4 text-regular m-sm-0 
                p-sm-3 list-reset text-left text-foreground-darkblue rounded-sm-1 ${styles.list}`}
              style={getStyles()}
            >
              {options.map((option, index) => (
                <OptionsElement
                  key={`${option}_${index}`}
                  id={`${uniqueId}_option_${index}`}
                  ref={itemRef => (elementsRef.current.items[index] = itemRef)}
                  role='option'
                  aria-selected={selectedItemIndex === index}
                  onClick={() => handleItemClick(index)}
                  data-testid={option.split(' ').join('-')}
                  className={`${styles.listItem} ${selectedItemIndex === index ? styles.selected : ''}`}
                  dangerouslySetInnerHTML={
                    setTextDangerously
                      ? {
                        __html: option
                      }
                      : undefined
                  }
                >
                  {setTextDangerously ? undefined : option}
                </OptionsElement>
              ))}
            </motion.ul>
          </>,
          containerRef.current
        )}
    </div>
  )
}

export default InlineSelector
