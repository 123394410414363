'use client'

import { useCustomUserData } from '@context/hooks/useCustomUserData'
import React, { useEffect, useRef, useState } from 'react'
import { IMadlibProps } from './Madlib.interface'
import styles from './Madlib.module.scss'
import MadlibOptions from './MadlibOptions.client'

function MadlibSection({
  select,
  saveButtonLabel,
  selectorBackgroundColor,
  snowplow,
}: IMadlibProps) {
  const [customUserData, setCustomUserData] = useCustomUserData({
    monitorCookieChanges: false,
  })
  const [selectedRole, setSelectedRole] = useState(
    customUserData.roles?.[0] || ''
  )
  const [selectOptions, setSelectOptions] = useState<{ [key: string]: any }>(
    Object.entries(customUserData as { [key: string]: any })?.reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value?.[0],
      }),
      {}
    )
  )
  const isLoadedRef = useRef<boolean>(false)

  useEffect(() => {
    if (!isLoadedRef.current) {
      setSelectedRole(customUserData.roles?.[0] || '')
      isLoadedRef.current = true
    }
  }, [customUserData])

  const onItemChange = (key) => (newSelectedItem) => {
    let selectKey = key
    if (selectKey === 'role') {
      setSelectedRole(newSelectedItem)
      selectKey = 'roles'
    } else {
      setSelectOptions((prevState) => ({
        ...prevState,
        [key]: newSelectedItem,
      }))
    }
    setCustomUserData(selectKey, [newSelectedItem])
  }

  if (!select?.length) return null

  return (
    <div className={`${styles.madlib}`} data-testid='madlib-container'>
      {select.map((selectItem, selectIndex) => {
        if (selectItem.omitFromRoles?.includes(`${selectedRole}`)) return null

        const roles = select.find(
          (s) => s.snowplowObjectKey === 'role'
        )?.options
        const role = roles?.find((option) => option.key === selectedRole)?.value
        const selectedOption = selectItem.options?.find(
          (option) => option.key === selectedRole
        )?.[0]
        const optionKey = selectItem.snowplowObjectKey
        const optionValue = selectItem?.options?.find(
          (option) => option.key === selectOptions?.[optionKey || '']
        )?.value

        const textToSplit =
          selectedOption?.textWhenSelected || selectItem.defaultText

        const splitText = textToSplit.split(/({placeholder})/)
        return splitText.map((splitedDefaultText, index) => {
          if (splitedDefaultText === '{placeholder}') {
            return React.createElement(MadlibOptions, {
              ...selectItem,
              select,
              onChange: onItemChange(selectItem.snowplowObjectKey),
              saveButtonLabel,
              selectorBackgroundColor,
              // eslint-disable-next-line react/no-array-index-key
              key: `madlib-option-${selectIndex}`,
              selectedRole,
              selectedValue: optionKey === 'role' ? role : optionValue,
              ariaLabel: index > 0 ? splitText[index - 1] : undefined,
              snowplow,
              testId: optionKey === 'role' ? 'roles' : optionKey,
            })
          }
          return splitedDefaultText
        })
      })}
    </div>
  )
}

export default MadlibSection
