'use client'

import { PreferencesContext } from '@context/PreferencesContext'
import SVGdropdown from '@fractal/primitives/SVGs/icons/dropdown'
import { InlineSelector } from '@fractal/primitives/atoms/InlineSelector'
import { Modal } from '@fractal/primitives/molecules/Modal'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { shuffleTags } from '@fractal/utils'
import { IMadlibProps, IMadlibSelect } from './Madlib.interface'
import useMadlibSubmittedObject from './MadlibSubmittedObject.client'
import styles from './Madlib.module.scss'
import trackEngagement from '../Snowplow/trackEngagement'
import FilterProvider from '../Filter/FilterProvider.client'
import FilterButtonToggle from '../Filter/FilterButtonToggle.client'

function MadlibOptions({
  select,
  selectType,
  defaultValue,
  options,
  onChange,
  tags,
  testId,
  saveButtonLabel,
  selectedRole,
  selectedValue,
  ariaLabel,
  selectorBackgroundColor,
  snowplow,
  snowplowObjectKey,
}: IMadlibSelect &
  IMadlibProps & {
    onChange: (arg: any) => void
    selectedRole?: string
    selectedValue?: string
    ariaLabel?: string
    testId?: string
  }) {
  const {
    preferences,
    selectedPreferences,
    setPreferences,
    selectedInternalTags,
    setSelectedInternalTags,
  } = useContext(PreferencesContext)
  const [filteredPreferences, setFilteredPreferences] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const firstRun = useRef(true)
  const shouldShuffle = useRef(true)
  const submittedObject = useMadlibSubmittedObject(select)
  const selectedTags = Array.from(
    new Set([...selectedPreferences, ...selectedInternalTags])
  )

  const fireSnowplowEvent = (
    submittedList,
    submittedText,
    eventType,
    submittedObj
  ) => {
    trackEngagement({
      eventType,
      submittedList,
      submittedText,
      unitName: 'madlib',
      unitLocation: 'inline',
      unitVariation: snowplow.unitVariation,
      submittedObject: submittedObj,
      componentId: null,
    })
  }

  useEffect(() => {
    if (selectType !== 'tags' || !tags?.length || !preferences?.length) return

    // Get allowed tags based on roles
    const allowedTags = tags
      .filter((tag) => !tag.omitFromRoles?.includes(`${selectedRole}`))
      .map((tag) => tag.tag)

    // Get tags to show
    const tagsToShow = allowedTags.map((t) => {
      const tagFromProps = tags?.find((tag) => tag.tag === t)
      const tagFromPreferences = preferences.find((tag) => tag.id === t)
      return {
        id: t,
        title:
          tagFromProps?.title ||
          tagFromPreferences?.title ||
          tagFromPreferences?.name,
      }
    })

    // Shuffle tags if we have not shuffled already
    if (tagsToShow.length && shouldShuffle.current) {
      const shuffledTags = shuffleTags(tagsToShow)
      setFilteredPreferences(shuffledTags)
      shouldShuffle.current = false
      return
    }

    // Try to maintain shuffle order
    const newArray = [] as any[]
    filteredPreferences.forEach((pref) => {
      if (tagsToShow.find((item) => item.id === pref.id)) newArray.push(pref)
    })
    tagsToShow.forEach((pref) => {
      if (!newArray.find((item) => item.id === pref.id)) newArray.push(pref)
    })
    setFilteredPreferences(newArray)
  }, [preferences, selectedRole, tags])

  const handleSelectChange = (option) => {
    const optionKey = options?.find((item) => item.value === option)?.key
    onChange?.(optionKey)
    const submittedObj = { ...submittedObject }
    submittedObj[snowplowObjectKey] = [optionKey]
    fireSnowplowEvent(option, option, 'select', submittedObj)
  }

  const openTags = () => {
    if (!isModalOpen) setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    firstRun.current = true
  }

  const handleTagsChange = (event) => {
    if (firstRun.current || selectedPreferences.length === event.length) {
      // ignore the first run because this function runs
      // with an empty array when the component starts
      firstRun.current = false
      return
    }

    const eventType = event.length > selectedTags.length ? 'select' : 'deselect'
    const changedItem =
      eventType === 'select'
        ? event.filter((x) => !selectedTags.includes(x))
        : selectedTags.filter((x) => !event.includes(x))

    setPreferences(event)
    setSelectedInternalTags(event)

    const newSelectedTags = event.filter((tag) =>
      filteredPreferences.find((item) => item.id === tag)
    )

    const submittedObj = {
      ...submittedObject,
      [snowplowObjectKey]: newSelectedTags,
    }

    fireSnowplowEvent(
      `[${newSelectedTags.toString()}]`,
      changedItem.toString(),
      eventType,
      submittedObj
    )
  }

  const handleKeyboardNavigation = (event) => {
    // 13 enter
    if (event.keyCode === 13) {
      event.preventDefault()
      openTags()
    }
  }

  const getTextToDisplay = () =>
    filteredPreferences.find((item) => selectedTags.includes(item.id))?.title ||
    defaultValue

  const allowedOptions =
    options
      ?.filter((option) => !option.omitFromRoles?.includes(`${selectedRole}`))
      .map((option) => option.value) || []

  return (
    <>
      {selectType === 'single' && (
        <InlineSelector
          options={allowedOptions}
          placeholder={defaultValue}
          onChange={handleSelectChange}
          label={ariaLabel}
          styledAs='madlib'
          backgroundColor={selectorBackgroundColor}
          selectedValue={selectedValue || defaultValue}
          data-testid={`madlib-${testId}`}
          setTextDangerously
        />
      )}
      {selectType === 'tags' && (
        <span className='p-sm-relative'>
          <span className='d-sm-inline-block'>
            <Modal
              position='right'
              modalOpen={isModalOpen}
              onRequestClose={() => closeModal()}
              closeModal={() => closeModal()}
              modalWidth={450}
              overlayStyle={{ zIndex: 100 }}
            >
              <div className='d-sm-flex flex-sm-column p-sm-6 h-sm-full o-sm-auto'>
                <h2 className='text-bold f-sm-6 mb-sm-5'>{defaultValue}</h2>
                <div className='o-sm-y-scroll'>
                  <FilterProvider unitName='madlib'>
                    {filteredPreferences?.length > 0 && (
                      <FilterButtonToggle
                        tags={filteredPreferences}
                        visibleTagCount={filteredPreferences.length}
                        onButtonToggle={handleTagsChange}
                        nbColumns={1}
                        includeInternalTags
                        selectedTags={selectedTags}
                      />
                    )}
                  </FilterProvider>
                </div>
                <div className='mt-sm-5'>
                  <button
                    type='button'
                    onClick={() => closeModal()}
                    data-testid='madlib-save-selections'
                    className='Btn Btn--shadow'
                  >
                    {saveButtonLabel}
                  </button>
                </div>
              </div>
            </Modal>
          </span>
          <span
            onClick={openTags}
            role='button'
            tabIndex={0}
            onKeyDown={handleKeyboardNavigation}
            data-testid={defaultValue?.split(' ').join('-')}
            className={`lh-reset text-nowrap p-sm-relative d-sm-inline text-bold pt-sm-1 px-sm-2
              pb-sm-2 rounded-sm-1 ${
                styles.optionsContainer
              } bg-background-high-${selectorBackgroundColor || 'ochreTint'}`}
          >
            {'\u00a0'}
            <span style={{ whiteSpace: 'normal', position: 'relative' }}>
              {getTextToDisplay()}
            </span>
            {'\u00a0'}
            <SVGdropdown
              className='vicon ml-sm-2 vertical-align-sm-middle'
              width='20'
              height='20'
            />
          </span>

          {filteredPreferences.filter((item) =>
            selectedPreferences.includes(item.id)
          ).length +
            // eslint-disable-next-line no-unsafe-optional-chaining
            selectedInternalTags?.length >
            1 && (
            <>
              {'\u00a0'}
              <div
                className={`p-sm-absolute d-sm-inline-flex flex-sm-justify-center flex-sm-align-start h-sm-4
                  w-sm-4 bottom-sm-0 circle-sm text-foreground-cream f-sm-3 text-bold h-md-5 w-md-5 f-md-5 ${styles.ellipsis}`}
              >
                ...
              </div>
            </>
          )}
        </span>
      )}
    </>
  )
}

export default MadlibOptions
