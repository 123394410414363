'use client'

import { PreferencesContext } from '@context/PreferencesContext'
import { useCustomUserData } from '@context/hooks/useCustomUserData'
import { useContext } from 'react'

const isBrowser = typeof window !== 'undefined'

export const useMadlibSubmittedObject = (select) => {
  const [customUserData] = useCustomUserData()
  const { selectedPreferences } = useContext(PreferencesContext)
  const selectedRole = customUserData.roles?.[0]

  const submittedObject = select.reduce((prev, curr) => {
    if (!curr.snowplowObjectKey) return prev

    if (curr.selectType === 'single') {
      const value =
        // eslint-disable-next-line no-nested-ternary
        curr.snowplowObjectKey === 'role'
          ? selectedRole
            ? [selectedRole]
            : []
          : customUserData[curr.snowplowObjectKey] || ''

      return {
        ...prev,
        [curr.snowplowObjectKey]: value,
      }
    }

    const tagsObject = curr.tags?.map((t) => t?.tag)
    const allInternalTags = isBrowser
      ? JSON.parse(localStorage.getItem('filter-user-internal-tags') || '[]')
      : []
    const internalTags = allInternalTags.filter((tag) =>
      tagsObject?.includes(tag)
    )

    const externalTags = selectedPreferences.filter((tag) =>
      tagsObject?.includes(tag)
    )

    return {
      ...prev,
      [curr.snowplowObjectKey]: [...internalTags, ...externalTags],
    }
  }, {})

  return submittedObject
}

export default useMadlibSubmittedObject
